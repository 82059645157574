// // app global css
@font-face
  font-family Oxygen
  src url('./fonts/oxygen-v10-latin-ext_latin-regular.woff2') format('woff2'), url('./fonts/oxygen-v10-latin-ext_latin-regular.woff') format('woff')
  font-weight 400

@font-face
  font-family Oxygen
  src url('./fonts/oxygen-v10-latin-ext_latin-700.woff2') format('woff2'), url('./fonts/oxygen-v10-latin-ext_latin-700.woff') format('woff')
  font-weight 700

@font-face
  font-family Oxygen
  src url('./fonts/oxygen-v10-latin-ext_latin-300.woff2') format('woff2'), url('./fonts/oxygen-v10-latin-ext_latin-300.woff') format('woff')
  font-weight 300

.container
  max-width 800px
  margin-left auto
  margin-right auto

.q-btn
  text-transform inherit

.dialog-header
  height 48px
  background #d2d2d2
  z-index 9999

.text-ellipsis
  overflow hidden
  text-overflow ellipsis

.header-bg
  background rgba(0, 0, 0, .5)

.header-text-hero
  font-size 4rem
  opacity .7

@media (max-width 1023px)
  .header-text-hero
    font-size 2rem
    opacity .9

.q-tab
  text-transform initial

.bg-orange-grad
  background-image linear-gradient(to bottom, #ff932c, #ff6200 99%)

.hover-active:hover
  opacity .8
  transition opacity .4s

.header-gradient-bg
  background-image linear-gradient(to top, rgba(248, 97, 2, 0), rgba(248, 97, 2, 1)) !important

.grid-card
  transition box-shadow 300ms ease

.admin-table .q-table__top
  padding 0

.news-article
  p
    font-weight 300
    font-size 1.2em

    img
      display block
      max-width 100%
      height auto

  h1, h2, h3, h4, h5, h6
    margin-bottom .5em
    margin-top 0
    line-height 1em
    font-weight 400

  h1
    font-size 3em

  h2
    font-size 2.5em

  h3
    font-size 2.2em

  h4
    font-size 2em

  h5
    font-size 1.75em

  h6
    font-size 1.5em

small
  font-size 90%